// Dependency
import React from "react";

//Components
import Adyen from "./adyen/adyen";
import Klarna from "./klarna/klarna";

// Template
const PaymentContainer = ({country, location}) => {

    return (
        <>
        {country === 'se' ? <Klarna location={location} /> : <Adyen country={country} location={location} />}
        </>
    )
}

export default PaymentContainer
