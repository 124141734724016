// Dependency
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCart } from "../../../contexts/cart-context";
import Storage from "../../../services/localStorage.service";
import UtilsService from "../../../services/utils.service";
import Loader from "../../loader/loader-overlay";
import AdyenAddress from "./adyen-address";
import AdyenForm from "./adyen-form";

// Styles
import * as adyenStyles from './adyen.module.scss'


// Template
const Adyen = ({ location, country }) => {
    const { cart, setCart, isLoading, toggleLoading } = useCart();
    const [address, setAddress] = useState(cart.selection.address);
    const [adyenLoaded, setAdyenLoaded] = useState(false);
    //const [totals, setTotals] = useState(cart.selection.totals);

    const updateAddress = (form) => {
        delete form.vatNumber;
        delete form.state;
        //TODO: Dont run if address isnt changed
        // console.log(form);
        // console.log(address)
        axios.defaults.headers.put['Accept'] = `*/*;api-token: ${cart.token}`;
        axios({
            method: 'put',
            url: `${process.env.CENTRA_URL}/payment-fields`,
            data: { address: form }
        }).then(response => {
            //console.log('UpdateAdress', response)
            //setAddress(response.data.selection.address)
            //setCart(response.data)
            setCart(response.data);
            //getAdyenForm();
        }).catch((error) => {
            console.log(error)
            let text = '';
            for (const [key, value] of Object.entries(error.response.data.errors)) {
                text += `${key} is ${value}`;
            }
            console.log(text);
            toggleLoading(false);

        });
    }

    const getAdyenForm = () => {
        axios.defaults.headers.post['Accept'] = `*/*;api-token: ${Storage.getWithExpiry('selection').token}`;
        let newAddress = { ...address, newsletter: true };
        axios({
            method: 'post',
            url: `${process.env.CENTRA_URL}/payment`,
            data: {
                //paymentMethod: `adyen-checkout-${country}`,
                address: newAddress,
                termsAndConditions: true,
                paymentReturnPage: `${location.origin}/${country}/thankyou/`,
                paymentFailedPage: `${location.origin}/${country}/failed/`
            }
        }).then(response => {
            const container = document.getElementById('adyenContainer');
            container.innerHTML = response.data.formHtml
            UtilsService.evaluate(container);
            setAdyenLoaded(true);
            toggleLoading(false);
        }).catch((error) => {
            console.log(error)
            let text = '';
            for (const [key, value] of Object.entries(error.response.data.errors)) {
                text += `${key} is ${value}`;
            }
            console.log(text);
            toggleLoading(false)
        });
    }

    const handleChangeAddress = () => {
        const adyenContainer = document.getElementById('adyenContainer');
        adyenContainer.innerHTML = '';
        setAdyenLoaded(false)
    }

    useEffect(() => {
        setAddress(cart.selection.address);
        if (address.email !== '' && address.firstName !== '' && address.lastName !== '' && address.address1 !== '' && address.zipCode !== '' && address.city !== '' && address.country !== '' && address.phoneNumber !== '') {
            getAdyenForm();
        }
    }, [cart, address])

    return (
        <div className={adyenStyles.cdAdyenContainer}>
            <Loader active={isLoading} />
            {adyenLoaded ?
                <AdyenAddress address={address} setAdyenLoaded={handleChangeAddress} />
                :
                <AdyenForm setIsLoading={toggleLoading} isLoading={isLoading} country={country} address={address} updateAddress={updateAddress} />}
            <div className={adyenStyles.cdAdyenPay} id="adyenContainer"></div>
        </div>
    )
}

export default Adyen
