// Dependency
import React from "react";
import { useTranslation } from "react-i18next";

// Styles
import * as adyenStyles from './adyen.module.scss'

const AdyenAddress = ({address, setAdyenLoaded}) => {
    const { t } = useTranslation();

    return (
        address ? <div className={adyenStyles.cdAdyenRow}>
            <h5>{t('thankYou.info')}</h5>
            <div className={adyenStyles.cdAdyenInfoBox}>
                <div className={adyenStyles.cdAdyenInfoBoxLeft}>
                    <p>{`${address.firstName} ${address.lastName}`}</p>
                    <div className={adyenStyles.cdAdyenInfoBoxInfo}>
                        <span>{address.address1},</span>
                        <span>{address.zipCode},</span>
                        <span>{address.city},</span>
                        <span>{address.email},</span>
                        <span>{address.phoneNumber}</span>
                    </div>
                </div>
                <a href={"#0"} onClick={() => { setAdyenLoaded(false) }}>{t('checkout.edit')}</a>
            </div>
        </div> : <></>
    )
}

export default AdyenAddress