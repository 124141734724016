// Dependency
import React, { useState } from "react";
import Select from 'react-select';

//Components
import Loader from "../../../components/loader/loader-overlay";
import { useCart } from "../../../contexts/cart-context";

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as adyenStyles from './adyen.module.scss'

const AdyenForm = ({ address, updateAddress, country, isLoading, setIsLoading }) => {

    //const [isLoading, setIsLoading] = useState(false);

    const { cart } = useCart();

    //Get ShipTo countries
    //TODO: Remove DK and SE from list
    const countries = cart.countries ? cart.countries.map(country => ({ ...country, label: country.name, value: country.country })) : [];

    // Translation Function
    const { t } = useTranslation();

    // TODO: Get this from shipTo countries in CEntra
    const options = {
        dk: [
            { value: 'DK', label: "Denmark" }
        ],
        no: [{ value: 'NO', label: "Norway" }],
        eu: countries,
        nl: [{ value: 'NL', label: "Netherlands" }],
        fr: [{ value: 'FR', label: "France" }],
        se: []
    };

    const [selectedOption, setSelectedOption] = useState(
        options[country].find((item) => item.value === cart.location.country)
    );

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: '50px',
        }),
    };

    const submitForm = () => {
        setIsLoading(true);
        const formInputs = document.querySelectorAll('.cd-adyen-form input');
        const addressForm = {}
        for (let i = 0; i < formInputs.length; i++) {
            if (formInputs[i].name) addressForm[formInputs[i].name] = formInputs[i].value;
        }
        updateAddress(addressForm);
    }

    return (
        <form className={`cd-adyen-form ${adyenStyles.cdAdyenRow}`} onSubmit={(e) => { e.preventDefault(); submitForm(); }}>
            <h5>{t('thankYou.info')}</h5>
            {address ? <div className={`${adyenStyles.cdAdyenInfoBox} ${adyenStyles.cdAdyenForm}`}>
                <Loader active={isLoading} />
                <div className={adyenStyles.cdAdyenFormRow}>
                    <label htmlFor="email">{t('checkout.email')}</label>
                    <div className={adyenStyles.cdAdyenInputs}>
                        <input id="email" type="email" name="email" placeholder={t('checkout.yourEmail')} defaultValue={address.email} required />
                    </div>
                </div>
                <div className={adyenStyles.cdAdyenFormRow}>
                    <label htmlFor="firstName">{t('checkout.name')}</label>
                    <div className={adyenStyles.cdAdyenInputs}>
                        <input id="firstName" type="text" name="firstName" placeholder={t('checkout.firstName')} defaultValue={address.firstName} required />
                        <input type="text" name="lastName" placeholder={t('checkout.lastName')} defaultValue={address.lastName} required />
                    </div>
                </div>
                <div className={adyenStyles.cdAdyenFormRow}>
                    <label htmlFor="address1">{t('checkout.address')}</label>
                    <div className={adyenStyles.cdAdyenInputs}>
                        <input id="address1" type="text" name="address1" placeholder={t('checkout.street')} defaultValue={address.address1} required />
                    </div>
                    <div className={adyenStyles.cdAdyenInputs}>
                        <input type="text" name="address2" placeholder={t('checkout.CO')} defaultValue={address.address2} />
                    </div>
                    <div className={adyenStyles.cdAdyenInputs}>
                        <input type="text" name="zipCode" placeholder={t('checkout.zip')} defaultValue={address.zipCode} required />
                        <input type="text" name="city" placeholder={t('checkout.city')} defaultValue={address.city} required />
                    </div>
                </div>
                <div className={adyenStyles.cdAdyenFormRow}>
                    <label htmlFor="country">{t('checkout.country')}</label>
                    <div className={adyenStyles.cdAdyenSelects}>
                        <Select
                            id="country"
                            name="country"
                            className={adyenStyles.cdSelect}
                            defaultValue={selectedOption}
                            options={options[country]}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className={adyenStyles.cdAdyenFormRow}>
                    <label htmlFor="phoneNumber">{t('checkout.phone')}</label>
                    <div className={adyenStyles.cdAdyenInputs}>
                        <input id="phoneNumber" type="text" name="phoneNumber" placeholder={t('checkout.phoneNumber')} defaultValue={address.phoneNumber} required />
                    </div>
                </div>
                <button className="cd-button">{t('checkout.save')}</button>
            </div> : <div></div>}
        </form>
    )
}

export default AdyenForm