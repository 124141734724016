// Dependency
import React from "react";

// Components
import { useCart } from "../../contexts/cart-context";
import Loader from "../loader/loader-overlay";

// Template
export default function OrderRewiev ({children}) {

    const { isLoading } = useCart();

    return (
        <div className="box-white">
            <Loader active={isLoading} />
            {children}
        </div>
    )
}
