//Dependencies
import React, { useEffect, useState } from "react";
import axios from "axios";

//Components
//import { useCart } from "../../../contexts/cart-context";
import UtilsService from "../../../services/utils.service";
import Storage from "../../../services/localStorage.service";

const Klarna = ({location}) => {
    //const { cart } = useCart();
    const [form, setForm] = useState();
    const selection = Storage.getWithExpiry('selection');
    useEffect(() => {
        if ( selection )  axios.defaults.headers.post['Accept'] = `*/*;api-token: ${selection.token}`;
        axios({
          method: 'post',
          url: `${process.env.CENTRA_URL}/payment`,
          data: {
            "paymentMethod": "klarna",
            "paymentReturnPage": `${location.origin}/se/thankyou/`,
            "paymentFailedPage": `${location.origin}/se/thankyou/`,
            "termsAndConditions": "true"
            // "additionalFields": {
            //   "mailchimp_campaign_id": "7897179",
            //   "mailchimp_landing_site": "https://silly-swartz-7a46f3.netlify.app/se"
            // }
          }
        }).then(response => {
          setForm(response.data);
        }).catch((error) => {
          console.log(error)
        });
    }, [])

    useEffect(() => {
      if ( form && form.formHtml ) {
        const checkoutContainer = document.getElementById('checkoutContainer'); // Reference to element already on page
        checkoutContainer.innerHTML = form.formHtml;
        UtilsService.evaluate(checkoutContainer);
      }

    }, [form])

    return (
        <div id="checkoutContainer"></div>
    )
}

export default Klarna