//Dependencies
import React, { useEffect } from "react";
import axios from "axios";

//Components
import { useCart } from "../../../contexts/cart-context";
import Storage from "../../../services/localStorage.service";
import UtilsService from "../../../services/utils.service";

// Styles
import * as ingridStyles from "./ingrid.module.scss"

// Assets


// Internationalization
import { useTranslation } from 'react-i18next';
import * as styles from "../../mini-cart/cart-footer/cart-footer.module.scss";

const Ingrid = (props) => {
    const { cart, setCart } = useCart();

    useEffect(() => {
        const shippingContainer = document.getElementById('shippingContainer'); // Reference to element already on page
        shippingContainer.innerHTML = Storage.getWithExpiry('selection').selection.pluginFields.shipwallet.snippet;
        //evaluate(checkoutContainer);
        UtilsService.evaluate(shippingContainer);
    }, [])

    //Add Centra Checkout Script (Maybe move to checkout or other component)
    useEffect(() => {

        const code = Storage.getWithExpiry('selection').selection.centraCheckoutScript;
        const centraScript = document.createElement('script');

        centraScript.innerHTML = code;

        document.body.appendChild(centraScript);

        return () => {
            document.body.removeChild(centraScript);
        }
    }, [])

    useEffect(() => {
        const handleData = (origdata) => {
            const url = `${process.env.CENTRA_URL}/payment-fields`
            axios.defaults.headers.put['Accept'] = `*/*;api-token: ${Storage.getWithExpiry('selection').token}`;
            axios({
                method: 'put',
                url: url,
                data: origdata.detail
            }).then(response => {
                //console.log('OrigData', origdata);
                setCart(response.data);
                window.CentraCheckout.resume(origdata.detail.additionalFields.suspendIgnore);
            }).catch((error) => {
                console.log(error)
            });
        }
        document.addEventListener('centra_checkout_callback', handleData);

        return () => {
            document.removeEventListener('centra_checkout_callback', handleData)
        }
    },[])

    // Translation Function
    const { t } = useTranslation();

    return (
        <div>
            <div className={ingridStyles.cdIngridLabel}>
                <h5>{t('checkout.delivery')}</h5>
                {/* <div className={ingridStyles.cdMaintenanceText}>
                    <span className="material-icons">info</span>
                    <p>{t('checkout.maintenance')}</p>
                </div> */}
            </div>
            <div className={ingridStyles.cdDeliverWrapper} id="shippingContainer"></div>
        </div>
    )
}

export default Ingrid